<template>
  <v-container fluid class="hero">
    <base-dialog-notification ref="registerConfirm" />
    <v-row align="center" justify="center">
      <v-window
          v-model="step"
          touchless
          :touch="{left: null, right: null}"
      >
        <v-window-item :value="0">
          <v-row>
            <v-col cols="12">
              <v-card-title class="py-8">
                <div class="text-center row justify-center">
                  <h1 class="font-weight-light" style="color: #3c4858; font-size: 36px">
                    <img width="40%" src="@/assets/FairwageLogo.png" />
                  </h1>
                </div>
              </v-card-title>
              <v-card-text
                  class="px-8"
              >
                <p>Fair Wage (Pty) Ltd (“the Company”) <span class="font-weight-black">does not provide domestic worker services, nor does the Company act as an intermediary for the provision of domestic workers as service providers.</span></p>
                <p>By using a service provider who offers domestic services which may be undertaken through the use of the Company’s platform/s, you agree and understand that such service provider is not an employee of the Company.</p>
                <p>It is recorded that in no way does the Company create, establish or set up any agency, partnership or employment relationship between the Company and the service provider. The Company does not employ the service provider, the user is solely responsible for the employment or contractual relations.</p>
                <p>Fair Wage (Pty) not be liable for the payment of salaries, fringe benefits, payroll taxes and other expenses of employment to the service provider.</p>
                <p class="font-weight-black">I have read and understood the disclaimer above:</p>
                <v-btn
                    color="primary"
                    elevation="0"
                    large
                    block
                    class=""
                    type="submit"
                    @click="gotoRegister"
                >I AGREE</v-btn>
                <v-btn
                    elevation="0"
                    block
                    large
                    outlined
                    color="primary"
                    class="my-6"
                    @click="backToLogin"
                >CANCEL</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="1">
          <v-card-title class="py-8">
            <div class="text-center row justify-center" id="signUpDetails">
              <h1 class="text-center text-md-h3 primary--text my-4">
                Sign Up Details
              </h1>
            </div>
          </v-card-title>
          <v-card-text class="">
            <v-form
              ref="registerFrm"
              @submit.prevent="registerUser"
              lazy-validation
            >
              <v-row>
                <v-col md="12" class="pb-0">
                  <v-text-field
                    v-model="register.firstName"
                    label="First Name"
                    name="name"
                    clearable
                    :rules="firstNameRules"
                    append-icon="mdi-account-arrow-left"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <v-text-field
                    v-model="register.lastName"
                    label="Last Name"
                    name="last_name"
                    clearable
                    :rules="lastNameRules"
                    append-icon="mdi-account-arrow-left"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <register-mobile-number-input
                      @updateMobileNumberInput="mobileNumberFunc"
                      :allowTestNumbers="true"
                      :enableAppendIcon="true"
                      :sizeDense="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <v-textarea
                    v-model.trim="register.fullAddress"
                    label="Residence address"
                    name="fullAddress"
                    :rules="requiredRules"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <v-text-field
                    v-model.trim="register.email"
                    label="Email"
                    name="email"
                    type="email"
                    :rules="emailRules"
                    append-icon="mdi-email"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <base-identification-type-picker
                    :initial="'South African ID'"
                    label="Identification Type"
                    outlined
                    dense
                    :chosen-identification-types.sync="idType"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <template v-if="idType == 'South African ID'">
                    <v-text-field
                      v-model.trim="register.idNumber"
                      label="ID Number"
                      name="idNumber"
                      clearable
                      outlined
                      required
                      :rules="requiredRules"
                      dense
                    />
                  </template>
                  <template v-else>
                    <v-text-field
                      v-model.trim="register.passport"
                      label="Passport Number"
                      name="passport"
                      clearable
                      outlined
                      required
                      :rules="requiredRules"
                      dense
                    />
                  </template>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <v-text-field
                    v-model="userPassword.password"
                    :append-icon="toggled == true ? 'mdi-eye' : 'mdi-eye-off'"
                    :append-icon-cb="switchToggle"
                    label="Password"
                    name="password"
                    :rules="passwordRules"
                    clearable
                    required
                    outlined
                    dense
                    :type="toggled == true ? 'text' : 'password'"
                    @click:append="switchToggle"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <v-text-field
                      v-model="userPassword.confirmPassword"
                      :append-icon="toggled == true ? 'mdi-eye' : 'mdi-eye-off'"
                      :append-icon-cb="switchToggle"
                      label="Confirm"
                      name="confirm"
                      outlined
                      required
                      dense
                      :rules="confirmPasswordRules"
                      :type="toggled == true ? 'text' : 'password'"
                      @update:error="checkPassword"
                      @click:append="switchToggle"
                  />

                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pb-0">
                  <div class="text-center mt-4">
                    <v-btn
                      color="secondary fairwage_dark_grey--text"
                      elevation="0"
                      large
                      block
                      class=""
                      type="submit"
                      v-bind:disabled="valid"
                      >SIGN UP</v-btn
                    >
                    <v-btn
                        elevation="0"
                        block
                        large
                        outlined
                        color="primary"
                        class="my-6"
                        @click="backToLogin"
                    >CANCEL</v-btn>
                  </div>
                </v-col>
              </v-row>
              </v-form>
            </v-card-text>
        </v-window-item>

      </v-window>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
import VueRecaptcha from "vue-recaptcha";
import RegisterMobileNumberInput from '@/components/Base/RegisterMobileNumberInput.vue'
export default {
  name: "Register",
  components: {
    VueRecaptcha,
    RegisterMobileNumberInput
  },
  data() {
    return {
      idType: "South African ID",
      appEnv: process.env.VUE_APP_ENV,
      vueAppRecaptchaSiteKey: process.env.VUE_RECAPTCHA_SITE_KEY,
      vueAppRecaptchaSecretKey: process.env.VUE_RECAPTCHA_SECRET_KEY,
      toggled: false,
      ping: "",
      step: 0,
      password: "",
      userPassword: {
        password: "",
        confirmPassword: "",
      },
      register: new User(),
      requiredRules: [(v) => !!v || "Required."],
      firstNameRules: [(v) => !!v || "First name is required"],
      lastNameRules: [(v) => !!v || "Last name is required"],
      idNumberRegex: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
      idNumberRules: [
        (v) => !!v || "Required.",
        (v) => {
          return (this.idNumberRegex.test(v) || "ID Number is not valid")
        }
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      telNumberRules: [(v) => !!v || "Mobile Number is required"],
      passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      passwordRules: [
        (v) => !!v || "Required.",
        (v) => {
          return (this.passwordRegex.test(v) ||"Min. 8 characters with at least one capital letter, a number and a special character.")
        },
      ],
      confirmPasswordRules: [
        (v) => !!v || "type confirm password",
        (v) => (v === this.userPassword.password || "The password confirmation does not match.")
      ],
      successMsg: "",
      errorMsg: "",
      valid: true,
      verifyOTP: {
        code: "",
      },
    }
  },
  apollo: {
    ping: {
      query: gql`
        query {
          ping
        }
      `,
      fetchPolicy: "network-only",
      result(data, key) {},
    },
  },
  methods: {
    mobileNumberFunc(value) {
      this.register.telNumber = value
    },
    switchToggle() {
      this.toggled = !this.toggled;
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    onVerify: function (response) {
      return (this.valid = !this.valid);
    },
    onExpired: function () {
      return (this.valid = !this.valid);
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    gotoRegister() {
      this.step = 1;
      this.$vuetify.goTo(0)
    },
    backToLogin() {
      this.$router.push("/")
    },
    checkPassword(invalid) {
      if (invalid) {
        console.log('Invalid is true')
        this.valid = true
      } else {
        console.log('Invalid is false,', this.passwordRegex.test(this.userPassword.password))
        this.valid = !this.passwordRegex.test(this.userPassword.password)
      }
    },
    async registerUser() {
      const self = this;
      this.$set(this.register, 'password', '')
      if (!self.$refs.registerFrm.validate()) {
        return;
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation createUser($user: UserCreateInput!) {
            createUser(user: $user) {
              id
            }
          }
        `,
        variables: {
          user: {
            firstName: this.register.firstName,
            lastName: this.register.lastName,
            email: this.register.email.toLowerCase().trim(),
            telNumber: this.register.telNumber,
            idNumber: this.register.idNumber,
            password: this.userPassword.password.trim(),
            passport: this.register.passport.trim(),
            employers: [this.register.id],
            role: "employer",
            fullAddress: this.register.fullAddress,
          },
        },
      }).then(async (userData) => {
        self.$token = userData.data.createUser.token;
        localStorage.setItem("token", self.$token);
        localStorage.setItem("role", "employer");
        await this.$refs.registerConfirm.open("Success", 'Congratulations, you are now registered to login to Fairwage! Please login with your Mobile number and password.', "success")
        this.$router.push("/")
      }).catch((error) => {
        const message = this.$_.get(error, "message", "There was a system error. Please try again or contact support.")
        this.$refs.registerConfirm.open("Error", `Unfortunately we were unable to register you because: ${message}`, "error")
        console.error(`Registration Failed ${JSON.stringify(error)}`);
      })
    },
  },
};
</script>

<style scoped>
.hero {
  background-size: cover;
  height: 100%;
}

.btn {
  text-transform: unset !important;
  margin-left: auto;
  margin-right: auto;
}
.no-border {
  border: 0 !important;
}
</style>
